.swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .swiper-slide img {
    width: 250px; 
    height: 300px; 
    object-fit: cover; 
  }
  
  .title, .designation {
    text-align: center;
    margin-top: 10px;
  }
  
  .title h2, .designation h4 {
    margin: 0;
  }
  