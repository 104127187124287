@font-face {
    font-family: swiper-icons;
    src: url("data:application/font-woff;charset=utf-8;base64,...") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  :root {
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
  }
  
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .main-slider{
    height:550px
  }
  
  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  
  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  
  .swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  
  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
  
  .swiper-container-3d {
    perspective: 1200px;
  }
  
  .swiper-container-3d .swiper-cube-shadow,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-wrapper {
    transform-style: preserve-3d;
  }
  
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }
  
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  }
  
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  }
  
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  }
  
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  }
  
  .swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
  }
  
  .swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
  
  .swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
  
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
  }
  
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
  
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
  }
  
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';
  }
  
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }
  
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: 'next';
  }
  
  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff;
  }
  
  .swiper-button-next.swiper-button-black,
  .swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000;
  }
  
  .swiper-button-lock {
    display: none;
  }
  
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }
  
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  
  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(.33);
    position: relative;
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1);
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.66);
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(.33);
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(.66);
  }
  
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(.33);
  }
  
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: .2;
  }
  
  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  
  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
  }
  
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
  }
  
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
  
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: .2s transform, .2s top;
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform;
  }
  
  .swiper-pagination-fraction {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
    color: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  
  .swiper-pagination-fraction .swiper-pagination-current {
    color: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  
  .swiper-pagination-fraction .swiper-pagination-total {
    color: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  
  .swiper-pagination-custom {
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  .swiper-pagination-custom span {
    color: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  
  .swiper-scrollbar {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    display: none;
  }
  
  .swiper-scrollbar-drag {
    background: var(--swiper-scrollbar-drag-color, rgba(0, 0, 0, .5));
    border-radius: 10px;
    cursor: pointer;
  }
  
  .swiper-scrollbar-cursor-drag {
    background: var(--swiper-scrollbar-drag-color, rgba(0, 0, 0, .5));
  }
  
  .swiper-container-horizontal > .swiper-scrollbar {
    bottom: 0;
    height: var(--swiper-scrollbar-height, 6px);
    left: 0;
    right: 0;
  }
  
  .swiper-container-vertical > .swiper-scrollbar {
    right: 0;
    width: var(--swiper-scrollbar-width, 6px);
    top: 0;
    bottom: 0;
  }
  
  .swiper-scrollbar-draggable {
    position: absolute;
  }
  
  .swiper-container-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
    height: 100%;
  }
  
  .swiper-container-vertical > .swiper-scrollbar .swiper-scrollbar-drag {
    width: 100%;
  }
  
  .swiper-container-horizontal > .swiper-scrollbar {
    border-radius: 10px;
  }
  
  .swiper-container-vertical > .swiper-scrollbar {
    border-radius: 10px;
  }

  
  