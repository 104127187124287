/* .sridhar-details-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .sridhar-details {
    max-width: 800px;
    width: 100%;
  }
  
  .header-content {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    flex-wrap: wrap;
    width: 100%;
  }
  
  .header-content h1 {
    flex: 1;
    margin-right: 20px;
  }
  
  .text-content {
    margin-top: 20px;
    flex: 1;
  }
  
  .block-title {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  
  .block-title img {
    max-width: 250px; 
    height: auto;
  }
  
  .col-lg-2 {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    flex-basis: 100%;
  }
  
  @media (min-width: 992px) {
    .sridhar-details-container {
      flex-direction: row-reverse;
    }
  
    .header-content {
      flex-direction: row;
    }
  
    .col-lg-2 {
      flex-basis: auto;
    }
  
    .block-title {
      justify-content: flex-start;
    }
  
    .block-title img {
      margin-top: -20px; 
    }
  }
  
  @media (max-width: 768px) {
    .sridhar-details-container {
      flex-direction: column;
      align-items: center;
    }
  
    .block-title img {
      margin: 20px 0;
      max-width: 200px; 
    }
  
    .sridhar-details-container {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .block-title img {
      max-width: 150px; 
    }
  }
   */
    span{
    padding-bottom: 20px;
   }
   h2{ 
    padding-bottom: 10px;
   }
  
  
   